import { Navigation, Pagination, Scrollbar, A11y, Mousewheel } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
import { Home } from "./pages/home";
import { Header } from "./components/header";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { SwiperContext } from "./state/SwiperContext";
import { menu } from "./data/menu";
import { menuMobile } from "./data/menuMobile";
import { SliderHothed } from "./components/SliderHothed";
import { useEffect, useState, useRef } from "react";
import { useSwiper } from "swiper/react";
import {ScrollInfinitoClientes} from "./components/clientes"

function App() {
  const [sliders, setSliders] = useState([]);
  const swiperRef = useRef(null);
  const [slider, setSlider] = useState(null);
  const [indiceActivo, setIndiceActivo] = useState(0);
  const isMobile=window.innerWidth<640;
  useEffect(() => {
    let _sliders = [];

    (function findSliders(menu) {
      Object.keys(menu).map((e) => {
        if (menu[e].diapo?.type === SliderHothed) {
          menu[e]["goTo"] = _sliders.length + 2;
          _sliders.push(menu[e]);
          return;
        }

        if (menu[e].children) {
          findSliders(menu[e].children);
        }
      });
    })(isMobile?menuMobile:menu);
    setSliders(_sliders);
  }, []);

  const onSwiper = (swiper) => {
    setSlider(swiper);
    swiperRef.current = swiper;
    const handleSlideChange = (e) => {
      setIndiceActivo(parseInt(`${e.activeIndex || 0}`));
    };

    swiper.on("slideChange", handleSlideChange);

    return () => {
      swiper.off("slideChange", handleSlideChange);
    };
  };

  console.log("total sliders", sliders.length);
  return (
    <SwiperContext.Provider value={{ slider, setSlider, indiceActivo, setIndiceActivo,}}>
      <div>
        {<Header menu= {isMobile?menuMobile:menu } />}
        <div>
          <Swiper
            onSwiper={onSwiper}
            direction="vertical"
            pagination={{
              clickable: true,
            }}
            mousewheel={true}
            keyboard={true}
            modules={[Pagination, Mousewheel]}
            className="mySwiper"
          >
        
            <SwiperSlide key={-1}>
              <div className="slide-content">
                <div className="w-full h-full bg-[url('images/imagenEntrada.png')]  bg-center bg-cover flex w-full h-full justify-center items-center">
                  <div className="">
                    <img src="logocuadro.png" width={350} />
                  </div>
                </div>
              </div>
            </SwiperSlide>
           
            <SwiperSlide key={-2}>
              <div className="slide-content">
                <div className="flex items-center justify-center w-full h-full bg-[url('images/fondo2.jpeg')]  md:bg-[url('images/40anios.png')] bg-center bg-cover flex w-full h-full justify-center items-center">
                  <div className="block md:hidden">
                      <h2 className="py-5 px-5 text-center bg-[#f4433ae8]" style={{lineHeight:1.1}}>
                          TIEMPO RECORD EN LOS TRABAJOS DE INSTALACIÓN  DE CABEZALES DE PERFORACIÓN
                      </h2>
                      <div className="h-[3vw] w-full bg-[#0b3354eb]">
                      </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="slide-content">
                <div className="flex w-full h-full justify-center items-center">
                <ScrollInfinitoClientes/>
                </div>
              </div>
            </SwiperSlide> */}
            {sliders.map((elem,key) => (
              <SwiperSlide key={key}>{elem.diapo}</SwiperSlide>
            ))}
          {/*   <SwiperSlide>
              <div className="slide-content">
               
              </div>
            </SwiperSlide> */}
          </Swiper>
        </div>
      </div>
    </SwiperContext.Provider>
  );
}

export default App;
