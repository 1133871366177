import { Swiper, SwiperSlide } from "swiper/react";

export function SliderHothed({ children,pdf,hideLogo }) {
  const handlePDf=()=>{
    window.open(`pdf/${pdf}`,"_blank")
  }
  return (
    <div className="slide-content relative flex flex-col" style={{ background: "#DFDFE1", }}>
      <div className="w-full flex absolute justify-end pt-1  pr-5 top-0">
        {!hideLogo&&<img src="logoGris.png" style={{ width: 200, height: "auto" }} />}
      </div>
      <div className="w-full flex  justify-center items-center">
        <div className="w-full flex items-center justify-center">{children}</div>
      </div>

      {pdf&&<div className="w-full flex absolute justify-end pb-10  pr-5 bottom-0">
        <img onClick={handlePDf} src="iconoPDF.jpg" style={{ width: 80, height: "auto" }} />
      </div>}
    </div>
  );
}
