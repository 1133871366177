import React, { useContext, useEffect, useState, useRef } from "react";
import { SwiperContext } from "../state/SwiperContext";
const width = 200;

export const MenuHothed = function ({ texto, params, nivel, swiper, _padre }) {
  const { slider,indiceActivo } = useContext(SwiperContext);
  const [isVisible, setVisible] = useState(false);
  const componenteRef = useRef(null);
  const [mostrarIzq, setMostrarIzq] = useState(false);
  const [seleccionado, setSeleccionado] = useState(false);

  useEffect(() => {
    
    if (_padre) {
      _padre.setSeleccionado(seleccionado);
    }
  }, [seleccionado]);

  useEffect(() => {
    if(swiper)
    swiper.on("slideChange", ()=>{
      console.log("3333333333333333333333333")
      if(`${indiceActivo}:::${params.goTo}`){
        console.log(`${indiceActivo}:::${params.goTo}`)
        console.log("paramsssssssssssss",params)
        setSeleccionado(true);
      } 
    });

    
    if (componenteRef.current && nivel == 2) {
      const componenteRect = componenteRef.current.getBoundingClientRect();
      const screenWidth = window.innerWidth;
      if (componenteRect.right + width * 2 > screenWidth) {
        setMostrarIzq(true);
      }
    }
    if (componenteRef.current && nivel == 1) {
      const componenteRect = componenteRef.current.getBoundingClientRect();
      const screenWidth = window.innerWidth;
      if (componenteRect.right + width  > screenWidth) {
        setMostrarIzq(true);
      }
    }

  /*   if (componenteRef.current && nivel == 2) {
      const componenteRect = componenteRef.current.getBoundingClientRect();
      const screenWidth = window.innerWidth;
      if (componenteRect.right + width * 2 > screenWidth) {
        setMostrarIzq(true);
      }
    } */
 
  }, []);


  const alineacion = nivel > 1 ? "left" : "center";
  const toggleMenu = () => {
    if (nivel > 2) return;
    setVisible(true);
  };
  const quitarMenu = () => {
    if (nivel > 2) return;
    setVisible(false);
  };

  function getProperty(nivel, propiedad) {
    const _nivel = `${nivel}`;
    const props = {
      right: {
        1: mostrarIzq ?`0px` : `unset`,
        2: !mostrarIzq ?`-${width}px` : `${width}px`
        /* 2: !mostrarIzq ? `-${width}px` : `${width}px`, */

      },
      top: {
        1: "unset",
        2: `-0px`,
      },
      "text-menus": {
        1: "text-sm",
        2: `text-xs`,
        3: `text-xs`,
      },
      "texto-a": {
        1: "text-center",
        2: `text-left`,
        3: `text-left`,
      },
    };

    return props[propiedad][_nivel] || "unset";
  }
  return (
    <li
      onClick={(e) => {
        console.log("haciendo click",params?.goTo);
        slider?.slideTo(params?.goTo);

        setSeleccionado(true);
        if (_padre) {
          _padre.setSeleccionado(true);
        }
        e.stopPropagation();
      }}
      className={`relative  ${isVisible && "bg-white"} ${nivel > 1 ? "py-2" : ""}`}
      onMouseLeave={quitarMenu}
      ref={componenteRef}
      style={{
        maxWidth: `${width}px`,
        width: nivel == 1 ? "unset" : `${width}px`,
        cursor: "pointer",
      }}
    >
      <a
        onMouseOver={toggleMenu}
        className={` text-[${
          seleccionado ? "#0b3354" : "#f4433a"
        }] flex h-full items-center justify-${nivel == 1 ? "center" : "left"} ${getProperty(
          nivel,
          "texto-a"
        )}  ${getProperty(nivel, "text-menus")} font-bold  text-${alineacion} px-2 py.1`}
      >
        <span>{texto}</span>
      </a>
      { params.children && isVisible&& (
        <ul
          className={`pl-4  z-1000000 absolute  bg-white ${nivel == 2 && "z-50"}`}
          style={{
            right: getProperty(nivel, "right"),
            top: getProperty(nivel, "top"),
          }}
        >
          {Object.keys(params.children).map((e, i) => {
            let _params = {};

            if (params.children) {
              _params = params.children[e];
              return (
                <MenuHothed
                  _padre={{ setSeleccionado }}
                  key={i}
                  texto={e}
                  params={_params}
                  nivel={nivel + 1}
                  {...{ swiper }}
                />
              );
            }
            return <br />;
          })}
        </ul>
      )}
    </li>
  );
};
