import React, { useContext, useEffect, useState, useRef } from "react";
import { SwiperContext } from "../state/SwiperContext";
import { MenuHothed } from "./MenuHothed";
import { MenuMobileHothed } from "./MenuMobileHothed";
import { SliderHothed } from "./SliderHothed";
import MenuIcon from "@mui/icons-material/Menu";
import { CSSTransition } from "react-transition-group";
import "./css/header.css";
export function Header({ menu, swiper }) {
  const isMobile=window.innerWidth<640;
  const [isMenuOpen, setIsMenuOpen] = useState(!isMobile);

  const { indiceActivo, setIndiceActivo } = useContext(SwiperContext);
  const [menuFinal, setMenuFinal] = useState({ ...menu });

  useEffect(() => {
    const menusGuionBajo = {};

    Object.keys(menu).map((e) => {
      if (`${e}`.startsWith("_")) {
        let clave = `${e}`.replace(/_/gi, "");
        menusGuionBajo[clave] = menu[e];
      }
    });

    let _sliders = [];
    let retorno = 1;
    function buscarIndice(menu, submenu) {
      Object.keys(menu).map((e) => {
        if (menu[e].diapo?.type == SliderHothed) {
          if (submenu == e) {
            retorno = _sliders.length + 2;
          }
          _sliders.push(1);
        }

        if (menu[e].children) {
          buscarIndice(menu[e].children, submenu);
        }
      });

      return retorno;
    }

    Object.keys(menuFinal).map((key) => {
      Object.keys(menusGuionBajo).map((singuion) => {
        if (singuion == key) {
          Object.keys(menusGuionBajo[singuion].children).map((submenu) => {
            menuFinal[key].children[submenu] = menusGuionBajo[singuion].children[submenu];
            menuFinal[key].children[submenu].goTo = buscarIndice(menu, submenu);
          });
        }
      });
    });
    Object.keys(menu).map((e) => {
      if (`${e}`.startsWith("_")) {
        delete menuFinal[e];
      }
    });

    setMenuFinal(menuFinal);
  }, []);
  const toggleMenu = () => {
    setIndiceActivo(0);
    setIsMenuOpen(!isMenuOpen)
  };
  return (
    <nav className={`${(isMenuOpen && indiceActivo==0)&& "bg-white"} md:bg-opacity-75  fixed z-50 w-full `}>
      <div className=" mx-auto  flex md:justify-between justify-center md:flex-row flex-col items-start md:items-stretch min-h-20">
        {
          <button onClick={toggleMenu} className="flex px-3 text-black items-center  ">
            <div className="bg-white p-2 rounded-xl">
              <MenuIcon
                style={{
                  color: "black",
                }}
              />
            </div>
          </button>
        }
        <CSSTransition in={isMenuOpen && indiceActivo==0} timeout={300} classNames="menu-show" unmountOnExit>
          <ul className={`md:flex md:flex-row h-auto items-stretch flex flex-col w-full md:w-auto`}>
            {Object.keys(menuFinal).map((e, index) => {
              const params = menu[e];
              if (window.innerWidth < 640)
                return <MenuMobileHothed key={index} texto={e} nivel={1} {...{ swiper, params }} />;
              return <MenuHothed key={index} texto={e} nivel={1} {...{ swiper, params }} />;
            })}
          </ul>
        </CSSTransition>
      </div>
    </nav>
  );
}
