
export const fila1 = [
    {url: "clientes/cliente_1.png",w: 400,h: 50,},
    {url: "clientes/cliente_2.png",w: 400,h: 50,},
    {url: "clientes/cliente_3.png",w: 400,h: 50,},
    {url: "clientes/cliente_4.png",w: 400,h: 50,},
    {url: "clientes/cliente_5.png",w: 400,h: 50,},
    {url: "clientes/cliente_6.png",w: 400,h: 50,},
    {url: "clientes/cliente_7.png",w: 400,h: 50,},
    {url: "clientes/cliente_8.png",w: 400,h: 50,},
    {url: "clientes/cliente_1.png",w: 400,h: 50,},
    {url: "clientes/cliente_2.png",w: 400,h: 50,},
    {url: "clientes/cliente_3.png",w: 400,h: 50,},
  ];

  export const fila2=[
    {url:"clientes/cliente_9.png",w:400,h:50},
    {url:"clientes/cliente_10.png",w:400,h:50},
    {url:"clientes/cliente_11.png",w:400,h:50},
    {url:"clientes/cliente_12.png",w:400,h:50},
    {url:"clientes/cliente_13.png",w:400,h:50},
    {url:"clientes/cliente_14.png",w:400,h:50},
    {url:"clientes/cliente_15.png",w:400,h:50},
    {url:"clientes/cliente_16.png",w:400,h:50},
    {url:"clientes/cliente_9.png",w:400,h:50},
    {url:"clientes/cliente_10.png",w:400,h:50},
    {url:"clientes/cliente_11.png",w:400,h:50},
   
  ]

  export const fila3=[
    {url:"clientes/cliente_17.png",w:400,h:50},
    {url:"clientes/cliente_18.png",w:400,h:50},
    {url:"clientes/cliente_14.png",w:400,h:50},
    {url:"clientes/cliente_20.png",w:400,h:50},
    {url:"clientes/cliente_21.png",w:400,h:50},
    {url:"clientes/cliente_22.png",w:400,h:50},
    {url:"clientes/cliente_23.png",w:400,h:50},
    {url:"clientes/cliente_7.png",w:400,h:50},
    {url:"clientes/cliente_17.png",w:400,h:50},
    {url:"clientes/cliente_18.png",w:400,h:50},
    {url:"clientes/cliente_14.png",w:400,h:50},
  ]