import React, { useContext, useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import {fila1,fila2,fila3} from "../data/clientes";
export function Cliente({ children, url, w, h }) {
  return (
    <div className={`flex justify-center items-center overflow-hidden w-[${w}px]`}>
      <img
        src={url}
        style={{
          width: "300px",
          objectFit: "cover",
        }}
      />
    </div>
  );
}

export function ScrollInfinitoClientes() {
  const elementRef = useRef(null);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const translateXF1 = ["0px","-3260px"];
  const translateXF2 = ["0px","-3260px"];
  const translateXF3 = ["0px","-3260px"];
  

  useEffect(() => {
    if (elementRef.current) {
      const { width, height } = elementRef.current.getBoundingClientRect();
      setSize({ width, height });
    }
  }, []);

  //{/* <div className="flex h-[100vh] justify-center items-center w-full bg-red-300"> */}
  return (
    <div className="flex flex-col h-[100vh] justify-center items-center w-full bg-white">
      {/* <div
        ref={elementRef}
        className="flex flex-col bg-gray-300 h-[60vh] p-3 "
        style={{ maxWidth: "1200px", overflow: "hidden" }}
      > */}
      <h2 className="text-[#EB2128] pb-10 text-[7vw] md:text-[2.4vw]">Nuestros Clientes</h2>
      <div
        ref={elementRef}
        className="flex flex-col bg-white h-[60vh] p-3 "
        style={{ maxWidth: "1200px", overflow: "hidden" }}
      >
        <div className="flex flex-nowrap overflow-hidden w-[200vw] h-[33%] gap-2">
          {fila1.map((e,i) => {
            return (
              <motion.div
              key={i}
                className="flex justify-center items-center"
                animate={{
                  translateX:translateXF1
                }}
                transition={{
                  duration: 22,
                  ease: "linear",
                  times: [0, 1],
                  repeat: Infinity,
                  repeatDelay: 0,
                  
                }}
              >
                <Cliente url={e.url} w={e.w} h={e.h}/> 
              </motion.div>
            );
          })}
        </div>
        <div className="flex flex-nowrap overflow-hidden w-[200vw]  h-[33%] gap-2">
        {fila2.map((e,i) => {
            return (
              <motion.div
              key={i}
                className="flex justify-center items-center"
                animate={{
                  translateX:translateXF2
                }}
                transition={{
                  duration: 22,
                  ease: "linear",
                  times: [0, 1],
                  repeat: Infinity,
                  repeatDelay: 0,
                  
                }}
              >
                <Cliente url={e.url} w={e.w} h={e.h}/> 
              </motion.div>
            );
          })}
        </div>
        <div className="flex flex-nowrap overflow-hidden w-[200vw]  h-[33%] gap-2">
        {fila3.map((e,i) => {
            return (
              <motion.div
              key={i}
                className="flex justify-center items-center"
                animate={{
                  translateX:translateXF3
                }}
                transition={{
                  duration: 22,
                  ease: "linear",
                  times: [0, 1],
                  repeat: Infinity,
                  repeatDelay: 0,
                  
                }}
              >
                <Cliente url={e.url} w={e.w} h={e.h}/> 
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
