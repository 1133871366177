import React, { useContext, useEffect, useState, useRef } from "react";
import { SwiperContext } from "../state/SwiperContext";
import { CSSTransition } from "react-transition-group";

import "./css/header.css";
const width = 200;
export const MenuMobileHothed = function ({ texto, params, nivel, swiper, _padre }) {
  const { slider, indiceActivo } = useContext(SwiperContext);
  const [isVisible, setVisible] = useState(false);
  const componenteRef = useRef(null);
  const [mostrarIzq, setMostrarIzq] = useState(false);
  const [seleccionado, setSeleccionado] = useState(false);
  const isMobile = window.innerWidth < 640;

  useEffect(() => {
    if (_padre) {
      _padre.setSeleccionado(seleccionado);
    }
  }, [seleccionado]);

  useEffect(() => {
    if (swiper)
      swiper.on("slideChange", () => {
        if (`${indiceActivo}:::${params.goTo}`) {
          setSeleccionado(true);
        }
      });

    if (componenteRef.current && nivel == 2) {
      const componenteRect = componenteRef.current.getBoundingClientRect();
      const screenWidth = window.innerWidth;
      if (componenteRect.right + width * 2 > screenWidth) {
        setMostrarIzq(true);
      }
    }
    if (componenteRef.current && nivel == 1) {
      const componenteRect = componenteRef.current.getBoundingClientRect();
      const screenWidth = window.innerWidth;
      if (componenteRect.right + width > screenWidth) {
        setMostrarIzq(true);
      }
    }

    /*   if (componenteRef.current && nivel == 2) {
      const componenteRect = componenteRef.current.getBoundingClientRect();
      const screenWidth = window.innerWidth;
      if (componenteRect.right + width * 2 > screenWidth) {
        setMostrarIzq(true);
      }
    } */
  }, []);

  const alineacion = nivel > 1 ? "left" : "center";
  const toggleMenu = () => {
    if (nivel > 2) return;
    setVisible(!isVisible);
  };

  function getProperty(nivel, propiedad) {
    const _nivel = `${nivel}`;
    const props = {
      right: {
        1: mostrarIzq ? `0px` : `unset`,
        2: !mostrarIzq ? `-${width}px` : `${width}px`,
        /* 2: !mostrarIzq ? `-${width}px` : `${width}px`, */
      },
      top: {
        1: "unset",
        2: `-0px`,
      },
      "text-menus": {
        1: "text-xl",
        2: `text-lg`,
        3: `text-md`,
      },
      "texto-a": {
        1: "text-center",
        2: `text-left`,
        3: `text-left`,
      },
      alturaMenu: {
        1: "60px",
        2: "40px",
        3: "40px",
      },
    };

    return props[propiedad][_nivel] || "unset";
  }
  return (
    <li
      onClick={(e) => {
        console.log("haciendo click", params?.goTo);
        slider?.slideTo(params?.goTo);
        toggleMenu(e);

        setSeleccionado(true);
        if (_padre) {
          _padre.setSeleccionado(true);
        }
        e.stopPropagation();
      }}
      className={`relative flex justify-center flex-col items-center  py-[10px] ${
        isVisible && "bg-white"
      } ${nivel > 1 ? "py-2" : ""}`}
      ref={componenteRef}
      style={{
        borderTop:"1px solid #bdbdbd",
        cursor: "pointer",
      }}
    >
      <a
        onMouseOver={!isMobile ? toggleMenu : () => {}}
        className={` text-[${
          seleccionado ? "#0b3354" : "#f4433a"
        }] flex h-full items-center justify-${nivel == 1 ? "center" : "left"} ${getProperty(
          nivel,
          "texto-a"
        )}  ${getProperty(nivel, "text-menus")} font-bold  text-${alineacion} px-2 py.1`}
      >
        <span>{texto}</span>
      </a>
      { (
        <CSSTransition in={params.children && isVisible} timeout={{ enter: 500, exit: 300 }} classNames="global-show" unmountOnExit>
          <ul
            className={`pl-0 md:pl-4  z-1000000 block md:absolute  bg-white ${
              nivel == 2 && "z-50"
            }`}
            style={{
              right: getProperty(nivel, "right"),
              top: getProperty(nivel, "top"),
            }}
          >
            {params.children&&Object.keys(params.children).map((e, i) => {
              let _params = {};

              if (params.children) {
                _params = params.children[e];
                return (
                  <MenuMobileHothed
                    _padre={{ setSeleccionado }}
                    key={i}
                    texto={e}
                    params={_params}
                    nivel={nivel + 1}
                    {...{ swiper }}
                  />
                );
              }
              return <br />;
            })}
          </ul>
        </CSSTransition>
      )}
    </li>
  );
};
