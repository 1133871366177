import { SliderHothed } from "../components/SliderHothed";
import {ScrollInfinitoClientes} from "../components/clientes"

export let menu = {
  "HOME": {
    children:{
      "ClIENTES":{
        
        diapo:(
          <SliderHothed hideLogo={true}>

          <div className="flex w-full h-full justify-center items-center">
          <ScrollInfinitoClientes/>
          </div>
          </SliderHothed>
        )
      },
      

    }
  },
  "HERRAMIENTAS DE PESCA Y MOLIENDA": {
    children: {
      "HERRAMIENTAS DE PESCA EXTERNA": {
        children: {
          "PESCANTE OVER SHOT SERIE 150": {
            diapo: (
              <SliderHothed pdf="pescantes_over_shot_seria_150.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="">
                    <img src="h1.png" className="w-[30vw] h-auto" />
                  </div>
                  <div className="">
                    <img src="h1_1.png" className="w-[30vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
          "PESCANTE OVER SHOT SERIE 70": {
            diapo: (
              <SliderHothed pdf="pescante_over_shot_serie_70.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="">
                    <img src="h2.png" className="w-[30vw] h-auto" />
                  </div>
                  <div className="">
                    <img src="h2_1.png" className="w-[30vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
          "PESCANTE OVER SHOT SERIE 10 Y 20": {
            diapo: (
              <SliderHothed pdf="pescante_over_shot_serie_10_y_20.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="">
                    <img src="herramientas/pescanteSerie10Y20.png" className="w-[30vw] h-auto" />
                  </div>
                  <div className="">
                    <img src="herramientas/pescanteSerie10Y20_2.png" className="w-[30vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
          TARRAJAS: {
            diapo: (
              <SliderHothed pdf="tarrajas.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="">
                    <img src="herramientas/tarrajas1.png" className="w-[50vw] h-auto" />
                  </div>
                  <div className="">
                    <img src="herramientas/tarrajas2.png" className="w-[10vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
        },
      },
      "HERRAMIENTAS DE PESCA INTERNAS": {
        children: {
          MACHUELO: {
            diapo: (
              <SliderHothed pdf="machuelos.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="pr-20">
                    <img src="herramientas/machuelo1.png" className="w-[40vw] h-auto" />
                  </div>

                  <div className="">
                    <img src="herramientas/machuelo2.png" className="w-[20vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
          ARPÓN: {
            diapo: (
              <SliderHothed pdf="arpon.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="">
                    <img src="herramientas/arpon1.png" className="w-[30vw] h-auto" />
                  </div>
                  <div className="">
                    <img src="herramientas/arpon2.png" className="w-[30vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
          "CANASTA DE CIRCULACIÓN INVERSA": {
            diapo: (
              <SliderHothed pdf="canasta_de_circulacion_inversa.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="">
                    <img src="herramientas/canastaC1.png" className="w-[30vw] h-auto" />
                  </div>
                  <div className="">
                    <img src="herramientas/canastaC2.png" className="w-[30vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
          "PESCANTE MAGNÉTICO": {
            diapo: (
              <SliderHothed pdf="pescante_magnetico.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="">
                    <img src="herramientas/pescanteM1.png" className="w-[40vw] h-auto" />
                  </div>
                  <div className="">
                    <img src="herramientas/pescanteM2.png" className="w-[30vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
          "BARRA MAGNÉTICA": {
            diapo: (
              <SliderHothed pdf="barra_magnetica.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="">
                    <img src="herramientas/barraM1.png" className="w-[30vw] h-auto" />
                  </div>
                  <div className="">
                    <img src="herramientas/barraM2.png" className="w-[30vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
          "BLOQUE DE IMPRESIÓN": {
            diapo: (
              <SliderHothed pdf="block_de_impresion.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="">
                    <img src="herramientas/bloqueI1.png" className="w-[30vw] h-auto" />
                  </div>
                  <div className="">
                    <img src="herramientas/bloqueI2.png" className="w-[30vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
          "ARPÓN PESCA CABLE DE ACERO": {
            diapo: (
              <SliderHothed pdf="arpon_pesca_cable_de_acero.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="">
                    <img src="herramientas/arponPescaAcero1.png" className="w-[30vw] h-auto" />
                  </div>
                  <div className="">
                    <img src="herramientas/arponPescaAcero2.png" className="w-[40vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
        },
      },
      "MARTILLO HIDRÁLICO Y PESCA": {
        children: {
          "MARTILLO HIDRÁULICO DE PESCA ": {
            diapo: (
              <SliderHothed pdf="martillos_hidraulico_de_pesca.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="">
                    <img src="herramientas/martilloH1.png" className="w-[30vw] h-auto" />
                  </div>
                  <div className="">
                    <img src="herramientas/martilloH2.png" className="w-[40vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
          "ACELERADOR DE PESCA": {
            diapo: (
              <SliderHothed pdf="aceleradore_hidraulico_de_pesca.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="">
                    <img src="herramientas/aceleradorP1.png" className="w-[40vw] h-auto" />
                  </div>
                  <div className="">
                    <img src="herramientas/aceleradorP2.png" className="w-[40vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
          "AMORTIGUADOR DE VIBRACIONES": {
            diapo: (
              <SliderHothed pdf="amortiguador_de_vibraciones.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="">
                    <img src="herramientas/amortiguadorV1.png" className="w-[40vw] h-auto" />
                  </div>
                  <div className="">
                    <img src="herramientas/amortiguadorV2.png" className="w-[40vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
        },
      },
      "JUNTAS DE SEGURIDAD PARA PERFORACIÓN": {
        diapo: (
          <SliderHothed pdf="juanta_de_seguridad_para_perforacion.pdf">
            <div className="w-full flex items-center justify-center">
              <div className="">
                <img src="herramientas/juntasSP1.png" className="w-[40vw] h-auto" />
              </div>
              <div className="">
                <img src="herramientas/juntasSP2.png" className="w-[35vw] h-auto" />
              </div>
            </div>
          </SliderHothed>
        ),
      },
      "HERRAMIENTAS DE MOLIENDA": {
        children: {
          "MOLINOS DE ALETAS Y WATERMELON": {
            diapo: (
              <SliderHothed pdf="molinos_de_aletas_y_watermelon.pdf">
                <div className="w-full flex items-stretch justify-center">
                  <div className="w-[45vw] h-[100vh] bg-white h-full px-[3vw] py-20">
                    <div className="h-full w-full ">
                      <h2 className="text-[#0b3354] text-[2vw]">MOLINOS</h2>
                      <div className="text-[0.7vw] leading-1 text-gray-500">
                        <p>
                          Los Súper Molinos Performance Drilling Jar™ son recubiertos con pastillas
                          Formaloy™ de Carburo de Tungsteno. Son diseñados para moler cualquier pez
                          atrapado que no puede ser retirado con un Pescante utilizando métodos
                          convencionales. Tienen una alta velocidad de penetración aminorando los
                          viajes, son muy resistentes a impactos y extremadamente efectivos para
                          moler metal incorporando la alta capacidad de sus vías de circulación para
                          eliminar los recortes.
                        </p>
                        <br />
                        <p>
                          Estos tipos de Molinos se utilizan normalmente para moler, TR (Apertura de
                          ventanas), Liners, Tubería Lavadora, TP, Tubing, Tubería de Producción,
                          cemento, Retenedores, Empacadores y prácticamente cualquier tipo de
                          herramienta atorada dentro del pozo o en agujero descubierto. Los Súper
                          Molinos se pueden producir con 4 ó 6 aletas y áreas de ataque planas,
                          Cóncavos, Cóncavos-convexos y tipo Piraña. Están disponibles de forma
                          inmediata en diámetros de 4 a 8 pulgadas, sin embargo, En caso de
                          necesitarse se pueden fabricar de acuerdo a los requerimientos del
                          cliente, en medidas que no estén plasmadas en la tabla de diámetros.
                        </p>
                      </div>
                      <br />

                      <h4 className="text-[#0b3354]">MOLINO DE ALETAS</h4>
                      <div className="text-[0.7vw] leading-1 text-gray-500">
                        <p>
                          Son fabricados con pastillas Formaloy™ de Carburo de Tungsteno de alta
                          resistencia, se pueden producir con 4 ó 6 aletas con áreas de ataque
                          Planos y Cóncavos y Cóncavos-convexos. Su versátil diseño les permite
                          moler brazos de Barrena Ampliadora, tapas de puente, pistolas de
                          perforación y cemento de tubería, y cuando se usan con área de ataque
                          cóncava, sirven para conformar la boca de pez antes de una Pesca. Los
                          Súper Molinos Performance están disponibles en diámetros de 4 a 8
                          pulgadas, sin embargo, si se requiere, bajo pedido, se pueden fabricar en
                          diámetros que van de 8 a 26 pulgadas de diámetro.
                        </p>
                      </div>
                      <br />

                      <h4 className="text-[#0b3354]">MOLINO CHATARRERO TIPO PIRAÑA</h4>
                      <div className="text-[0.7vw] leading-1 text-gray-500">
                        <p>
                          Son manufacturados con pastillas Formaloy™ de Carburo de Tungsteno de alta
                          resistencia. Son utilizados con gran eficacia para moler todo tipo de
                          chatarra en agujero descubierto o dentro de la TR, facilitan las tareas de
                          molienda incluso cuando se cortan los pescados más duros, como empacadores
                          de aleación de acero, Herramientas de Compresión, Pistolas de Perforación,
                          Tubería de Perforación, Tubería Cementada, Juntas de Herramientas,
                          Ensanchadoras, Cuchillas de Ensanchadoras y Brocas de Roca. Los Súper
                          Molinos tipo Piraña están disponibles para uso inmediato con diámetros de
                          4 a 8 pulgadas, sin embargo, si se requiere se pueden fabricar con
                          diámetros que van desde 8 a 26 pulgadas a petición del consumidor.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-[55vw] flex flex-col justify-center">
                    <img src="herramientas/molino1.png" className="w-[40vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
          "ZAPATAS LAVADORES": {
            diapo: (
              <SliderHothed pdf="zapatas_lavadoras.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="">
                    <img src="herramientas/zapatasL1.png" className="w-[40vw] h-auto" />
                  </div>
                  <div className="">
                    <img src="herramientas/zapatasL2.png" className="w-[35vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
          "TUBERÍA LAVADORA": {
            diapo: (
              <SliderHothed pdf="tuberia_lavadora.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="">
                    <img src="herramientas/tuberiaL1.png" className="w-[40vw] h-auto" />
                  </div>
                  <div className="">
                    <img src="herramientas/tuberiaL2.png" className="w-[35vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
          "CANASTA COLECTORA": {
            diapo: (
              <SliderHothed pdf="canasta_colectora.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="">
                    <img src="herramientas/canastaCo1.png" className="w-[40vw] h-auto" />
                  </div>
                  <div className="">
                    <img src="herramientas/canastaCo2.png" className="w-[35vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
        },
      },
      "ESCARIADORES PARA TR": {
        diapo: (
          <SliderHothed pdf="escariadores_para_tr.pdf">
            <div className="w-full flex items-center justify-center">
              <div className="">
                <img src="herramientas/escariadoresTR1.png" className="w-[50vw] h-auto" />
              </div>
              <div className="">
                <img src="herramientas/escariadoresTR2.png" className="w-[30vw] h-auto" />
              </div>
            </div>
          </SliderHothed>
        ),
      },
      CORTATUBOS: {
        children: {
          "INTERNO MECÁNICO": {
            diapo: (
              <SliderHothed pdf="cortatubos_interno_mecanico.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="">
                    <img src="herramientas/cortatubosIM1.png" className="w-[50vw] h-auto" />
                  </div>
                  <div className="">
                    <img src="herramientas/cortatubosIM2.png" className="w-[30vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
          "EXTERNO MECÁNICO": {
            diapo: (
              <SliderHothed pdf="cortatubos_externo_mecanico.pdf">
                <div className="w-full flex items-stretch justify-center bg-white h-[100vh]">
                  <div className="w-[45vw] h-[100vh] bg-white h-full px-[3vw] py-20">
                    <div className="h-full w-full ">
                      <h2
                        className="text-[#0b3354] text-[2vw] "
                        style={{
                          lineHeight: "1.2",
                        }}
                      >
                        <span className="text-[#f4433a]">CORTATUBOS</span>
                        <br />
                        <span>EXTERNO MECÁNICO</span>
                      </h2>
                      <br />
                      <div className="text-[0.7vw] leading-1 text-gray-500">
                        <p>
                          Los Cortadores Externos de Tubería de Perforación y de Revestimiento
                          tienen resortes que empujan automáticamente las Cuchillas para
                          proporcionar rapidez y eficiencia en la recuperación de secciones largas
                          de la tubería, ya sea de Perforación o Revestimiento. El sistema de
                          resorte para las Cuchillas tiene la característica de evitar que sea
                          aplicada tensión excesiva de peso de perforación que podría causar que las
                          Cuchillas se quemen o se rompan antes de que se haga el corte.
                        </p>
                        <br />
                        <p>
                          El cuerpo del Cortador Externo está diseñado para controlar la tensión que
                          se adquiere durante la secuencia del corte, por lo que las Cuchillas serán
                          controladas por el resorte principal.
                        </p>
                      </div>
                      <br />

                      <div className="w-full flex flex-row justify-center">
                        <div className="w-[20vw]">
                          <img src="herramientas/cortatubosCM2.png" className="w-[40vw] h-auto" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-[55vw] flex flex-row justify-center items-center">
                    <div className="w-[25vw] items-center">
                      <img src="herramientas/cortatubosCM1.png" className="w-[40vw] h-auto" />
                    </div>
                  </div>
                </div>
              </SliderHothed>
            ),
          },
          "INTERNO / EXTERNO HIDRÁULICO": {
            diapo: (
              <SliderHothed pdf="cortatubos_interno___externo_hidraulico.pdf">
                <div className="w-full flex items-center justify-center">
                  <div className="">
                    <img src="herramientas/cortadoresIEH1.png" className="w-[40vw] h-auto" />
                  </div>
                  <div className="">
                    <img src="herramientas/cortadoresIEH2.png" className="w-[40vw] h-auto" />
                  </div>
                </div>
              </SliderHothed>
            ),
          },
        },
      },
    },
  },
  "HERRAMIENTAS DE PERCUSIÓN": {
    children: {
      "MARTILLOS  HIDROMECÁNICO": {
        children: {
          "GRIFFITH OIL TOOLS": {
            diapo: (
              <SliderHothed pdf="griffith_oil_tools.pdf">
                <div className=" w-full flex items-center justify-center bg-white">
                  <div className=" w-[50vw] h-[100vh]   flex flex-col relative z-30 items-end justify-center">
                    <div className=" w-[50vw] px-[2vw] ">
                      <h3
                        className=" leading-9 text-center pt-[5vw]"
                        style={{
                          lineHeight: "1.2",
                        }}
                      >
                        <span className="text-[#f4433a] text-[2vw]"> GRIFFITH OIL TOOLS</span>
                        <br />
                        <span className="text-[#0b3354] text-[2vw]">
                          {" "}
                          El Martillo Hidráulico / Mecánico para Perforación de Griffith®.
                        </span>
                      </h3>
                      <div className="px-4 py-2">
                        <div className="border-2 border-gray-400"></div>
                      </div>
                      <p className="py-2 text-[15px] text-gray-500">
                        <b className="text-black">DEMORA HIDRÁULICA: </b>Esta característica permite
                        que la fuerza de martilleo pueda ser variada hacia abajo en el pozo con tan
                        sólo cambiar la sobretensión. La demora también permite que el freno del
                        carrete pueda ser aplicado, lo cual protege el equipo de levantamiento
                        cuando dispara el Martillo.
                      </p>
                      <p className="py-2 text-[15px] text-gray-500 ">
                        <b className="text-black">CERROJO MECÁNICO:</b> El cerrojo mecánico sujeta
                        firmemente el mandril en una posición neutra en relación al cuerpo del
                        Martillo, previniendo cualquier martilleo inesperado mientras se efectúan
                        las conexiones o se hacen corridas dentro y fuera del pozo. El movimiento
                        lineal del cerrojo no es afectado por el torque. Este cerrojo previene el
                        desgaste de la herramienta durante las operaciones normales de perforación.
                        No es requerida una junta de seguridad o procedimientos especiales para su
                        manejo en el piso del taladro o pozo abajo.
                      </p>
                    </div>
                  </div>
                  <div className="w-[60vw] h-auto flex flex-col items-stretch">
                    <div className="flex justify-center">
                      <div className="relative w-[60vh] min-w-[70vh] flex justify-center">
                        <img src="herramientas/griffith.jpg" width={400} />
                      </div>
                    </div>
                  </div>
                </div>
              </SliderHothed>
            ),
          },
          "PUMA MASTER TOOLS": {
            diapo: (
              <SliderHothed pdf="puma_master_tools.pdf">
                <div className=" w-full flex items-center justify-center bg-white">
                  <div className=" w-[50vw] h-[100vh] flex flex-col relative z-30 items-end justify-center">
                    <div className=" w-[50vw] px-[2vw]  ">
                      <h3
                        className=" leading-9 text-center pt-[5vw]"
                        style={{
                          lineHeight: "1.2",
                        }}
                      >
                        <span className="text-[#f4433a] text-[2vw]"> PUMA MASTER TOOLS</span>
                        <br />
                        <span className="text-[#0b3354] text-[2vw]"> Martillo Hidromecánico</span>
                      </h3>
                      <div className="px-4 py-2">
                        <div className="border-2 border-gray-400"></div>
                      </div>
                      <p className="py-2 text-[15px] text-gray-500 ">
                        <b className="text-black">Puma Master Tools </b> ha diseñado un Martillo
                        Hidromecánico de Doble Acción que utiliza toda la fuerza extrema de la
                        Energía Potencial generada en una sección mecánica e hidráulica de una forma
                        controlada, utilizando el diseño básico de retardo hidráulico, para la
                        acumulación de energía a todo lo largo de la sarta de perforación y
                        liberando súbitamente un mandril deslizante que impacta contra una camisa
                        golpeadora, haciendo el efecto de martilleo. La fuerza de golpeo del
                        martillo es controlada directamente con la tensión aplicada para evitar
                        deslizamientos inesperados del mandril durante las corridas. El martillo
                        cuenta con un sistema de Seguro Mecánico que evita que se dispare
                        inesperadamente, permitiendo que opere tanto en tensión como en compresión,
                        su diseño robusto lo hace el más potente de los martillos hidráulicos
                        existentes en el mercado.
                      </p>
                    </div>
                  </div>
                  <div className="w-[50vw] h-auto flex flex-col items-stretch">
                    <div className="flex justify-center">
                      <div className="relative w-[60vh] min-w-[70vh] flex justify-center">
                        <img src="herramientas/puma.png" width={400} />
                      </div>
                    </div>
                  </div>
                </div>
              </SliderHothed>
            ),
          },
          "PERFORMANCE DRILLING JAR": {
            diapo: (
              <SliderHothed pdf="wzl.pdf">
                <div className=" w-full flex items-center justify-center bg-white">
                  <div className=" w-[40vw] h-[100vh] px-[2vw]  flex flex-col relative z-30 items-end justify-center">
                    <div className="h-[30vw] w-[30vw]">
                      <h3 className=" leading-9 text-center pt-[5vw]">
                        <span className="text-[#f4433a] text-[2vw]"> PERFORMANCE DRILLING JAR</span>
                        <br />
                        <span className="text-[#0b3354] text-[2vw]">
                          {" "}
                          Hydraulic / Mechanical Drilling Jar (HMJ)
                        </span>
                      </h3>
                      <div className="px-4 py-2">
                        <div className="border-2 border-gray-400"></div>
                      </div>
                      <p className="py-2 text-[15px] text-gray-500">
                        The Wenzel Downhole Hydraulic / Mechanical Drilling Jar (HMJ) is a double
                        acting jar, designed to deliver hydraulic delay when jarring in the up
                        direction, and mechanical release when jarring in the down direction. The
                        HMJ incorporates a latch mechanism to keep the jar locked in the neutral
                        position and eliminate unexpected jarring while tripping or racking back in
                        the derrick.
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="w-[60vw] h-auto flex flex-col items-stretch">
                    <div className="flex justify-center">
                      <div className="relative w-[60vh] min-w-[70vh] flex justify-center">
                        <img src="herramientas/wzl.png" className="w-[30vw]" />
                      </div>
                    </div>
                  </div>
                </div>
              </SliderHothed>
            ),
          },
        },
      },
    },
  },
  "HERRAMIENTAS DE TUBERÍA FLEXIBLE": {
    diapo: (
      <SliderHothed className="">
        <div
          style={{ backgroundSize: "auto 100%" }}
          className="bg-white w-full flex items-center justify-center"
        >
          <div className="   flex h-[100vh]  justify-center relative ">
            <div className="w-[50vw] flex justify-center items-center">
              <div className="w-[30vw]">
                <h3 className=" leading-9 text-center pt-[5vw]">
                  <span className="text-[#f4433a] text-[2vw]">HERRAMIENTAS PARA</span>
                  <br></br>
                  <span className="text-[#0b3354] text-[2vw]">TUBERÍA FLEXIBLE</span>
                </h3>
                <div className="px-4 py-2">
                  <div className="border-2 border-gray-400"></div>
                </div>
                <p className="py-2 text-[15px] text-gray-500">
                  Contamos con la gama más amplia de herramientas de fondo y accesorios para tubería
                  flexible, personal altamente capacitado para el desarrollo de programas e
                  intervenciones especializadas, con el objetivo de lograr operaciones eficientes,
                  obteniendo el mejor desempeño de las herramientas de TF.
                </p>
              </div>
            </div>
            <div className=" w-[50vw] flex flex-col justify-evenly items-center">
              <div className="">
                <img src="herramientas/operadoresm2.jpeg" className="h-[50vh] w-auto" />
              </div>
            </div>
          </div>
          <div className="w-[50vw] h-auto flex flex-col items-stretch"></div>
        </div>
      </SliderHothed>
    ),
  },

  "SERVICIOS DE SOLDADURA DE CABEZALES": {
    children: {
      "SOLDADURA CON SISTEMA MCFLY": {
        diapo: (
          <SliderHothed pdf="soldadura_de_cabezales_con_sistema_mcfly.pdf">
            <div className="bg-white w-full flex items-center justify-center">
              <div className=" w-[30vw] h-[100vh] px-5  flex flex-col relative z-30">
                <div className="flex items-center justify-center">
                  <img src="herramientas/mcfly5.png" className="relative w-[20vw]" />
                </div>
                <div className="px-4 py-4">
                  <div className="border-2 border-gray-400"></div>
                </div>
                <div>
                  <h3 className=" leading-9 text-center pt-6">
                    <span className="text-[#0b3354] text-[2vw]">SOLDADURA DE CABEZALES CON</span>
                    <span className="text-[#f4433a] text-[2vw]"> SISTEMA Mcfly</span>
                  </h3>
                  <p className="py-4 text-[15px] text-gray-500">
                    El sistema McFly para precalentamiento y post calentamiento, fue desarrollado
                    para proporcionar a los operadores, un método práctico y funcional, para
                    mantener las temperaturas consideradas esenciales durante todo el proceso de
                    soldadura, logrando elevar la temperatura de manera uniforme y controlada ideal
                    para realizar los trabajos soldadura de cabezales que van de 400º a 600º F.
                  </p>
                </div>
                <div className="flex justify-center items-center flex-grow">
                  <img
                    onClick={() => {
                      window.open(
                        "https://drive.google.com/file/d/19KQ73SvzS6jkkURSnFjQcEV3Ywqg_puA/view",
                        "_blank"
                      );
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                    src="herramientas/mcfly6.png"
                    className="relative w-[10vw]"
                  />
                </div>
              </div>
              <div className="w-[70vw] h-auto flex flex-col items-stretch">
                <div className="flex justify-end">
                  <div className="h-[50vh] relative w-[60vh] min-w-[70vh] flex justify-end">
                    <img
                      src="herramientas/mcfly1.png"
                      className="relative h-[98.9%] right-[-10.1vh]"
                    />
                  </div>
                  <div className="h-[50vh] relative w-[60vh]  min-w-[70vh] flex justify-end">
                    <img src="herramientas/mcfly2.png" className="relative h-[99%] " />
                  </div>
                </div>
                <div className="flex justify-end">
                  <div className="h-[50vh] relative w-[70vh] min-w-[70vh]  flex justify-end">
                    <img
                      src="herramientas/mcfly3.png"
                      className="relative h-full right-[-10.1vh]"
                    />
                  </div>
                  <div className="h-[50vh] relative w-[70vh]  min-w-[70vh] flex justify-end">
                    <img src="herramientas/mcfly4.png" className="relative h-full " />
                  </div>
                </div>
              </div>
            </div>
          </SliderHothed>
        ),
      },
      "SOLDADURA CON SISTEMA  HOT HED ": {
        diapo: (
          <SliderHothed pdf="soldadura_de_cabezales_con_sistema_hot_hed.pdf">
            <div className="bg-white w-full flex items-center justify-center">
              <div className=" w-[30vw] h-[100vh] px-[2vw]  flex flex-col relative z-30">
                <div>
                  <h3 className=" leading-9 text-center pt-[5vw]">
                    <span className="text-[#0b3354] text-[2vw]">
                      SOLDADURA DE CABEZALES CON SISTEMA
                    </span>
                    <span className="text-[#f4433a] text-[2vw]"> HOT</span>
                    <span className="text-[#b0b0b0] text-[2vw]"> HED</span>
                  </h3>
                  <div className="px-4 py-2">
                    <div className="border-2 border-gray-400"></div>
                  </div>
                  <p className="py-2 text-[15px] text-gray-500">
                    Integridad de la soldadura 100% segura.
                  </p>
                  <p className="py-2 text-[15px] text-gray-500">
                    El sistema Hot Hed ofrece de una forma eficiente la instalación de cabezales
                    soldables cumpliendo con las prácticas recomendadas por API para soldaduras
                    seguras, corte, trabajos en caliente, aplicación y prueba de la soldadura en la
                    industria del petróleo, petroquímica y geotérmica.
                  </p>
                  <p className="py-2 text-[15px] text-gray-500">
                    Más de 80 mil instalaciones exitosas realizadas en México, en las Regiones
                    Norte, Sur y Marina.
                  </p>
                  <div className="flex justify-center items-center flex-grow gap-[1vw]">
                  <img
                    onClick={() => {
                      window.open("https://www.youtube.com/watch?v=WX5bOnu18Ds&t=33s", "_blank");
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                    src="herramientas/mcfly6.png"
                    className="relative w-[10vw]"
                  />
                  <img
                    onClick={() => {
                      window.open("https://www.youtube.com/watch?v=vffUrJJegPE", "_blank");
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                    src="herramientas/mcfly7.png"
                    className="relative w-[10vw]"
                  />
                </div>
                </div>
                
              </div>
              <div className="w-[70vw] h-auto flex flex-col items-stretch">
                <div className="flex justify-end">
                  <div className="h-[50vh] relative w-[60vh] min-w-[70vh] flex justify-end">
                    <img
                      src="herramientas/soldadoraS1.png"
                      className="relative h-[98.9%] right-[-10.1vh]"
                    />
                  </div>
                  <div className="h-[50vh] relative w-[60vh]  min-w-[70vh] flex justify-end">
                    <img src="herramientas/soldaduraS2.png" className="relative h-[99%] " />
                  </div>
                </div>
                <div className="flex justify-end">
                  <div className="h-[50vh] relative w-[70vh] min-w-[70vh]  flex justify-end">
                    <img
                      src="herramientas/soldaduraS3.png"
                      className="relative h-full right-[-10.1vh]"
                    />
                  </div>
                  <div className="h-[50vh] relative w-[70vh]  min-w-[70vh] flex justify-end">
                    <img src="herramientas/soldaduraS4.png" className="relative h-full " />
                  </div>
                </div>
              </div>
            </div>
          </SliderHothed>
        ),
      },
      "HÁBITAT MODULAR": {
        diapo: (
          <SliderHothed pdf="habitat_modular.pdf">
            <div
              style={{ backgroundSize: "auto 100%" }}
              className="bg-white w-full flex items-center justify-center bg-left  bg-cover bg-no-repeat bg-[url('images/habitat1.jpg')]"
            >
              <div className="w-[65vw] h-auto flex flex-col items-stretch"></div>
              <div className=" bg-[linear-gradient(to_right,_transparent_0%,_white_20%,_white_100%)] w-[35vw] h-[100vh] px-[5vw]  flex flex-col justify-center relative ">
                <div className="">
                  <h3 className=" leading-9 text-center pt-[5vw]">
                    <span className="text-[#f4433a] text-[2vw]"> HÁBITAT</span>
                    <br></br>
                    <span className="text-[#0b3354] text-[2vw]">MODULAR</span>
                  </h3>
                  <div className="px-4 py-2">
                    <div className="border-2 border-gray-400"></div>
                  </div>
                  <p className="py-2 text-[15px] text-gray-500">
                    Seguridad operativa en zonas de alto riesgo.
                  </p>
                  <p className="py-2 text-[15px] text-gray-500">
                    Cuando la seguridad es lo más importante, el uso del hábitat modular para
                    trabajos calientes, asegura el aislamiento de las zonas productoras, mitigando
                    todo riesgo durante los trabajos de instalación, reparación o mantenimiento, sin
                    detener la producción.
                  </p>
                  <div className="flex justify-evenly mt-10">
                    <img
                      onClick={() => {
                        window.open("https://www.youtube.com/watch?v=Y1nQ_2p-fXE", "_blank");
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                      src="herramientas/mcfly6.png"
                      className="relative w-[7vw]"
                    />
                    <img
                      onClick={() => {
                        window.open("https://www.youtube.com/watch?v=ZKBnk0k4HoU", "_blank");
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                      src="herramientas/mcfly7.png"
                      className="relative w-[7vw]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </SliderHothed>
        ),
      },
    },
  },
  "MONUMENTOS DE ABANDONO": {
    children: {
      "MONUMENTO DE ABANDONO": {
        diapo: (
          <SliderHothed className="">
            <div className="bg-white w-full flex items-center justify-center ">
              <div className=" w-[30vw] h-[100vh] px-[2vw]  flex flex-col relative z-30 justify-center">
                <div className="h-[30vw]">
                  <h3 className=" leading-9 text-center pt-[5vw]">
                    <span className="text-[#f4433a] text-[2vw]"> MONUMENTO</span>
                    <span className="text-[#0b3354] text-[2vw]"> DE ABANDONO </span>
                  </h3>
                  <div className="px-4 py-2">
                    <div className="border-2 border-gray-400"></div>
                  </div>
                  <p className="py-2 text-[15px] text-gray-500">
                    Servicio de corte y recuperación de tuberías de revestimiento para instalación
                    de monumento de abandono.
                  </p>
                  <p className="py-2 text-[15px] text-gray-500">
                    Taponamiento con placa y mástil con placa descriptiva con información del pozo
                    abandonado.
                  </p>
                </div>
              </div>
              <div className="w-[70vw] h-auto flex flex-col items-stretch">
                <div className="flex justify-end">
                  <div className="h-[100vh] relative w-[60vh] min-w-[70vh] flex justify-end">
                    <img
                      src="herramientas/menumento1.png"
                      className="relative h-[100%] right-[-10.1vh]"
                    />
                  </div>
                  <div className="h-[100vh] relative w-[60vh]  min-w-[70vh] flex justify-end">
                    <img src="herramientas/menumento2.png" className="relative h-[100%] " />
                  </div>
                </div>
              </div>
            </div>
          </SliderHothed>
        ),
      },
      "SERVICIOS DE CORTE EN FRÍO": {
        diapo: (
          <SliderHothed className="">
            <div className="bg-white w-full flex items-center justify-center ">
              <div className=" w-[30vw] h-[100vh] px-[2vw]  flex flex-col relative z-30 justify-center">
                <div className="h-[30vw]">
                  <h3 className=" leading-9 text-center pt-[5vw]">
                    <span className="text-[#0b3354] text-[2vw]">SERVICIO DE </span>
                    <span className="text-[#f4433a] text-[2vw]"> CORTE FRIO</span>
                  </h3>
                  <div className="px-4 py-2">
                    <div className="border-2 border-gray-400"></div>
                  </div>
                  <p className="py-2 text-[15px] text-gray-500">
                    Proporcionamos cortes y biselado en frío para tuberías en diámetros desde 7” a
                    20” para diferentes materiales y espesores, realizado con personal técnico
                    altamente calificado, capacitado y con gran experiencia.
                  </p>
                  <p className="py-2 text-[15px] text-gray-500">
                    Los equipos aseguran un control preciso de la velocidad de corte y biselado.
                  </p>
                </div>
              </div>
              <div className="w-[70vw] h-auto flex flex-col items-stretch">
                <div className="flex justify-end">
                  <div className="h-[100vh] relative w-[60vh] min-w-[70vh] flex justify-end">
                    <img
                      src="herramientas/servicioCorte1.png"
                      className="relative h-[100%] right-[-10.1vh]"
                    />
                  </div>
                  <div className="h-[100vh] relative w-[60vh]  min-w-[70vh] flex justify-end">
                    <img src="herramientas/servicioCorte2.png" className="relative h-[100%] " />
                  </div>
                </div>
              </div>
            </div>
          </SliderHothed>
        ),
      },
      "SERVICIOS DE SOLDADURA EN EQUIPOS": {},
    },
  },
  "SERVICIOS DE INGENIERIA ": {
    children: {
      "INGENIERIA EN PESACA, MOLIENDA Y TUBERÍA FLEXIBLE": {
        diapo: (
          <SliderHothed hideLogo={true} className="">
            <div className=" w-full flex items-center justify-center ">
              <div className=" w-[50vw] h-[100vh] px-[2vw]  flex flex-col relative z-30 items-end justify-center">
                <div className="h-[30vw] w-[30vw]">
                  <h3 className=" leading-9 text-center pt-[5vw]">
                    <span className="text-[#f4433a] text-[2vw]">
                      {" "}
                      SERVICIOS DE INGENIERIA PARA OPERACIONES DE PESCA, MOLIENDA Y
                    </span>
                    <br />
                    <span className="text-[#0b3354] text-[2vw]">
                      {" "}
                      OPERACIONES CON HERRAMIENTAS DE TUBERÍA FLEXIBLE.
                    </span>
                  </h3>
                  <div className="px-4 py-2">
                    <div className="border-2 border-gray-400"></div>
                  </div>
                  <p className="py-2 text-[15px] text-gray-500">Experiencia y Éxito.</p>
                  <p className="py-2 text-[15px] text-gray-500">
                    Nuestro equipo de especialistas desarrollan programas eficientes para la
                    intervención de pesca, molienda, e intervenciones con herraminetas de tubería
                    flexible, optimizando el desempeño de nuestras herramientas logrando los
                    objetvos y reducciendo los tiempos operativos.
                  </p>
                </div>
              </div>
              <div className="w-[50vw] h-auto flex flex-col items-stretch">
                <div className="flex justify-end">
                  <div className="h-[100vh] relative w-[60vh] min-w-[70vh] flex justify-end">
                    <img
                      src="herramientas/serviciosVV1.png"
                      className="relative h-[100%] right-[-10.1vh]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </SliderHothed>
        ),
      },
      "OPERADORES ESPECIALISTAS EN PESACA ,  MOLIENDA Y TUBERÍA FLEXIBLE": {
        diapo: (
          <SliderHothed hideLogo={true} className="">
            <div
              style={{ backgroundSize: "auto 100%" }}
              className="bg-white w-full flex items-center justify-center bg-right  bg-cover bg-no-repeat bg-[url('images/operadoresEPM1.png')]"
            >
              <div className=" items-end bg-[linear-gradient(to_right,_transparent_0%,_white_20%,_white_100%)] w-[50vw] h-[100vh] px-[5vw]  flex flex-col  justify-center relative ">
                <div className="w-[30vw]">
                  <h3 className=" leading-9 text-center pt-[5vw]">
                    <span className="text-[#f4433a] text-[2vw]">
                      {" "}
                      OPERADORES ESPECIALISTAS EN PESCA, MOLIENDA Y
                    </span>
                    <br></br>
                    <span className="text-[#0b3354] text-[2vw]">
                      OPERACIONES CON HERRAMIENTAS DE TUBERÍA FLEXIBLE.
                    </span>
                  </h3>
                  <div className="px-4 py-2">
                    <div className="border-2 border-gray-400"></div>
                  </div>
                  <p className="py-2 text-[15px] text-gray-500">
                    Asegure el éxito en las operaciones de pesca, molienda o en intervenciones con
                    herraminetas de tubería flexible, nuestros Operadores Especialista en
                    herraminetas de pesca, molienda y tubería flexible, brinda confianza y gran
                    experiancia en los campos exploratorios y de desarrollo de las Regiones, Norte.
                  </p>
                </div>
              </div>
              <div className="w-[50vw] h-auto flex flex-col items-stretch"></div>
            </div>
          </SliderHothed>
        ),
      },
    },
  },
  "_HOME": {
    children:{
      
      "CONTACTO":{
        diapo:(
          <SliderHothed hideLogo={true}>
             <div className="bg-[#0D3967] h-[100vh] w-[100vw] flex flex-col">
                  <div className="flex flex-grow">
                    <div className="flex w-[50vw] justify-center items-center">
                      <div className="vw-[300px]">
                        <img src="logocuadro.png" className="vw-[300px]" />
                      </div>
                    </div>

                    <div className="flex w-[50vw] justify-center items-center">
                      <div className="w-[400px]">
                      <h2 className="text-[#E92327]">Mail:</h2>
                        <h2 className="text-[#E92327]">contacto@hothedmexico.mx</h2>
                     
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col bg-red-500 text-white h-[5vw] text-[1vw] justify-evenly px-10 text-center">
                    <p>
                      Nota de Confidencialidad: contiene información propiedad de Hot Hed Oil
                      Tool, S.A. de C.V. y Hot Hed México, S. A. de C. V. Cualquier diseminación,
                      distribución o reproducción está estrictamente prohibido.
                    </p>
                    <p>
                      Confidentiality Note: contains information property of Hot Hed Oil Tool, S.A.
                      de C.V. and Hot Hed Mexico, S. A. de C. V. Any dissemination, distribution or
                      reproduction is strictly prohibited.
                    </p>
                  </div>
                </div>
          </SliderHothed>

        )
      }

    }
  },
};
